function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        return true;
    }
    if (/iPhone|iPod/i.test(userAgent)) {
        return true;
    }
    if (/iPad/i.test(userAgent)) {
        return false;
    }
    return false;
}

module.exports = {
    isMobile
}
