const tableDataCommon = [
    {
        parameter: 'el',
        desc: '人脸父级容器，接收一个class,id或者html元素',
        type: 'String | HtmlElement',
        values: '',
        default: '-'
    },
    {
        parameter: 'face',
        desc: '当前对象是基于FaceMesh.js 的二次封装，默认值是effet.js 自带参数，可以传FaceMesh.js的参数体，具体可以查看参数集合页面',
        type: 'Object',
        values: '',
        default: '{\n   maxNumFaces:1,\n   refineLandmarks:true,\n   minDetectionConfidence:0.5,\n   minTrackingConfidence:0.5\n }'
    },
    {
        parameter: 'type',
        desc: 'type 来进行不同人脸模式的切换，可选类型：addFace（人脸添加），checkLogin（人脸登录），checkSleep（睡眠检测），clockIn（人脸打卡）',
        type: 'String',
        values: 'addFace，checkLogin，checkSleep，clockIn',
        default: 'checkLogin'
    },
    {
        parameter: 'before',
        desc: '人脸框架执行前的前置函数，一但使用了before函数，则会覆盖框架自带的before函数，具体可查看参数集合的示例',
        type: 'function',
        values: '',
        default: '-'
    },
    {
        parameter: 'action',
        desc: '人脸动作函数，一但使用action函数，则会覆盖框架自带的action函数 ，具体可查看参数集合的示例',
        type: 'function',
        values: '',
        default: '-'
    },
    {
        parameter: 'callBack',
        desc: '阶段性回调函数，具体回调返回，可查看参数集合页面',
        type: 'function',
        values: '',
        default: '-'
    },
    {
        parameter: 'faceStyle',
        desc: '人脸样式网格样式，可以给人脸画一个网格线，单独给眼睛，鼻子，嘴巴轮廓单独上色 ，具体可查看参数集合的示例',
        type: 'Object',
        values: '',
        default: '-'
    }
];

const createTableData = (extraParams) => [
    ...tableDataCommon,
    ...extraParams
];

const faceAdd = createTableData([
    {
        parameter: 'appearance',
        desc: '当为false的时候，则不应用任何样式，睡眠检测，人脸打卡目前没有任何样式',
        type: 'Boolean',
        values: 'true | false',
        default: 'true'
    },
    {
        parameter: 'size',
        desc: '控制当前人脸容器大小',
        type: 'String',
        values: 'min | mid | max',
        default: 'mid'
    },
    {
        parameter: 'addFaceDistance',
        desc: '人脸添加距离，人脸正对摄像头的距离，默认20厘米',
        type: 'Number',
        values: '',
        default: '20'
    }
]);

const faceLogin = createTableData([
    {
        parameter: 'blur',
        desc: '登录成功后，镜头的模糊度',
        type: 'Number',
        values: '',
        default: '8'
    },
    {
        parameter: 'appearance',
        desc: '当为false的时候，则不应用任何样式，睡眠检测，人脸打卡目前没有任何样式',
        type: 'Boolean',
        values: 'true | false',
        default: 'true'
    },
    {
        parameter: 'size',
        desc: '控制当前人脸容器大小',
        type: 'String',
        values: 'min | mid | max',
        default: 'mid'
    },
    {
        parameter: 'threshold',
        desc: 'lips：张张嘴的阈值，eye：眨眨眼的阈值，headShake：左右摇头的阈值',
        type: 'Object',
        values: '',
        default: '{lips:0.05,eye:0.011,headShake:0.01}'
    }
]);

const facePunch = createTableData([
    {
        parameter: 'punchDistance',
        desc: '打卡距离，人脸正对摄像头的距离，默认20厘米',
        type: 'Number',
        values: '',
        default: '20'
    },
    {
        parameter: 'punchSuccessColor',
        desc: '打卡验证成功后的外框颜色',
        type: 'String',
        values: '',
        default: '#00d6e1'
    }
]);

const sleepDetection = createTableData([
    {
        parameter: 'sleepEarThreshold',
        desc: '睡眠检测，当眼睛闭眼的阈值',
        type: 'Number',
        values: '',
        default: '0.2'
    },
    {
        parameter: 'sleepTime',
        desc: '睡眠检测，单位秒，当闭眼多久则判断是在睡眠',
        type: 'Number',
        values: '',
        default: '2'
    },
    {
        parameter: 'sleepContinuousPush',
        desc: '当前检测到睡眠，是否持续推送消息，默认为false,只推送一次',
        type: 'Boolean',
        values: 'true | false',
        default: 'false'
    }
]);

let tableObj = {
    faceAdd,
    faceLogin,
    facePunch,
    sleepDetection
}

function getTableDataByType(type) {
    return tableObj[type]
}

export { getTableDataByType };
