
let vueMain = `import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引入框架
import 'face-effet/effet/effet.css'
import faceEffet from 'face-effet/effet/effet.js'
// 注册为全局
Vue.prototype.$faceEffet = faceEffet

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')`

let vueComponents = `<template>
  <div>
    <div id="dangqface">
    </div>
    <button @click="restart">重新验证</button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      editor: null
    };
  },
  mounted() {
      this.$faceEffet.init({
        el: 'dangqface',
        callBack: (data) => {
          console.log(data);
        }
      })
  },
  methods: {
    restart(){
      this.$faceEffet.restart()
    }
  }
};
</script>
<style>
#dangqface{
  width: 100%;
  height: 600px;
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;
  overflow: hidden;
  border: 1px red solid;
}
</style>`


export default {
    vueMain,vueComponents
}
