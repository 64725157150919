import Vue from 'vue'
import VueRouter from 'vue-router'
import installPage from '@/views/V1.0.0/views/install'
import introduce from "@/views/V1.0.0/views/introduce";
import leftHand from "@/views/V1.0.0/views/left-hand";
import parameterSet from "@/views/V1.0.0/views/parameter-set";
import faceLogin from "@/views/V1.0.0/views/face-login";
import facePunch from "@/views/V1.0.0/views/face-punch";
import sleepDetection from "@/views/V1.0.0/views/sleep-detection";
import faq from "@/views/V1.0.0/views/faq";
import attention from "@/views/V1.0.0/views/attention";
import func from "@/views/V1.0.0/views/func";
import feedback from "@/views/V1.0.0/views/feedback";
import faceAdd from "@/views/V1.0.0/views/face-add";
import projectPractice from "@/views/V1.0.0/views/project-practice";
import customStyle from "@/views/V1.0.0/views/custom-style";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'introduce',
    component: introduce
  },
  {
    path: '/install',
    name: 'installPage',
    component: installPage
  },
  {
    path: '/left-hand',
    name: 'left-hand',
    component: leftHand
  },
  {
    path: '/parameter-set',
    name: 'parameter-set',
    component: parameterSet
  },
  {
    path: '/face-login',
    name: 'face-login',
    component: faceLogin
  },
  {
    path: '/face-add',
    name: 'face-add',
    component: faceAdd
  },
  {
    path: '/face-punch',
    name: 'face-punch',
    component: facePunch
  },
  {
    path: '/sleep-detection',
    name: 'sleep-detection',
    component: sleepDetection
  },
  {
    path: '/faq',
    name: 'faq',
    component: faq
  },
  {
    path: '/attention',
    name: 'attention',
    component: attention
  },
  {
    path: "/func",
    name: "func",
    component: func
  },
  {
    path: "/feedback",
    name: "feedback",
    component: feedback
  },
  {
    path: "/projectPractice",
    name: "projectPractice",
    component: projectPractice
  },
  {
    path: '/customStyle',
    name: 'customStyle',
    component: customStyle
  }
]

const router = new VueRouter({
  routes
})

export default router
