<template>
<div>

  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">快速上手</span></h3>
    </el-col>
  </el-row>

  <div class="effet-panel">
    🗂️ 每个使用方式可能存在不同的<span>差异</span>
  </div>


  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">Vue中的使用方式一</span></h3>
    </el-col>
  </el-row>

  <el-row >
    <el-col :span="24" >

      <el-row >
        <el-col :span="24">
          <span class="introduce-character-text">npm安装完成后，在main.js中引入</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div v-highlight>
          <pre>
            <code class="javascript">{{useVue.vueMain}}</code>
          </pre>
          </div>
        </el-col>
      </el-row>

      <el-row >
        <el-col :span="24">
          <span class="introduce-character-text">在vue中使用</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div v-highlight>
          <pre>
            <code class="vue">{{useVue.vueComponents}}</code>
          </pre>
          </div>
        </el-col>
      </el-row>

    </el-col>
  </el-row>


  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">Vue中的使用方式二</span></h3>
    </el-col>
  </el-row>

  <el-row >
    <el-col :span="24" >

      <el-row >
        <el-col :span="24">
          <span class="introduce-character-text">如果使用npm到网络出现的问题，建议切换为淘宝镜像安装，或者可以直接在浏览器输入

          </span>

          <br/>

          <span class="introduce-character-text">
          https://unpkg.com/face-effet/effet/effet.css
          </span>

          <br/>

          <span class="introduce-character-text">
          https://unpkg.com/face-effet/effet/effet.js
          </span>

          <br/>

          <span class="introduce-character-text">
          将两个effet.css 跟 effet.js直接放在本地项目assets或者其他资源文件中即可
          </span>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div v-highlight>
          <pre>
            <code class="html">{{useVue2.vueMain}}</code>
          </pre>
          </div>
        </el-col>
      </el-row>


    </el-col>
  </el-row>


</div>
</template>

<script>
import useVue from "@/views/V1.0.0/views/left-hand/useVue";
import useVue2 from "@/views/V1.0.0/views/left-hand/useVue2";
export default {
  name: "index",
  data(){
    return {
      useVue:useVue,
      useVue2:useVue2
    }
  }
}
</script>

<style scoped>

</style>
