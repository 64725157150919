<template>
<div>

  <div class="adv-main">

    <div class="adv-main-item" v-for="(item,index) in advList"
    :class="item.advStatus?'adv-main-item-true':''"
    :style="{border:item.advStatus?'1.5px solid var(--current-page-line-color)':'1.5px dashed var(--current-page-line-color)'}"
    >
        <img src="~@/assets/add.png" alt="" v-if="!item.advStatus" class="adv-main-img-default">
        <p v-if="!item.advStatus">{{item.advTitle}}</p>

        <a v-if="item.advStatus" :href="item.toUrl" >
          <img :src="item.imgUrl" :alt="item.advTitle" :title="item.advTitle" class="adv-main-img" />
        </a>
    </div>

  </div>

</div>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
      advList:[
        {
          advStatus: true,
          advTitle: '合约门',
          imgUrl:'https://heyuemen.com/public/home/images/logo.jpg',
          toUrl:'https://heyuemen.com/',
        },
        {
          advStatus:false,
          advTitle:'广告投放,加V：typsusan'
        }
      ]
    }
  },
}
</script>

<style >
.adv-main{
  width: 100%;
  height: auto;
  margin-top: 20px;
}
.adv-main-item{
  width: 200px;
  height: 50px;
  cursor: pointer;
  margin-bottom: 10px;
  color: var(--current-page-line-color);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}
.adv-main-item-true::before{
  position: absolute;
  content: '广告';
  width: 50px;
  height: 15px;
  font-size: 12px;
  background-color: var(--current-page-el-dropdown-menu-bg);
  color: var(--current-page-font-color);
  transform: translate(-50%,-50%);
  right: -25px;
  top: 7px;
  line-height: 15px;
  text-align: center;
}

.adv-main-item .adv-main-img-default{
  width: 15px;
  height: 15px;
  margin-bottom: -3.2px;
}
.adv-main-item p{
  margin-left: 8px;
}
.adv-main-img{
  width: 197px;
  height: 53px;
  border-radius: 2px;
}
</style>
