let faceLoginCode = `<template>
  <div>
    <el-row v-if="faceLoginState">
      <el-col :span="24">
        <el-form ref="form" :model="form" label-width="80px">

          <el-form-item label="账号">
            <el-input v-model="form.name"></el-input>
          </el-form-item>

          <el-form-item label="密码">
            <el-input v-model="form.pwd"></el-input>
          </el-form-item>

          <el-form-item label="登录">
            <el-button>登录</el-button>
            <el-button @click="faceLoginChange">切换人脸登录</el-button>
          </el-form-item>

        </el-form>
      </el-col>
    </el-row>

    <el-row v-if="!faceLoginState">
      <el-col :span="24">
        <div ref="faceVueTest" id="faceId"></div>
      </el-col>
      <el-col :span="24">
        <el-button @click="faceLoginChange">切换密码登录</el-button>
      </el-col>
    </el-row>
  </div>
</template>
  
 <script>
 // $faceEffet 对象是在main.js 注册好的全局对象
 export default {
   name: "index",
   data(){
     return {
      form:{
        name:"",
        pwd:""
      },
      faceLoginState:true,
      faceInitState:false,// 用于标记人脸是否已经初始化，如果初始化则重启
     }
   },
   created(){
       // 在当前页面刚刚加载的时候，可以先进行缓存操作，因为在init函数也会调用当前cache函数
       // 如果已缓存，则会忽略掉，所以不必担心重复缓存的问题，
       // 从而调用init函数是不需要缓存而提升人脸容器加载速度
       this.$faceEffet.cache();
   },
   // 页面销毁的时候，需要调用close函数
   beforeDestroy(){
     if (this.$faceEffet){
       this.$faceEffet.close();
     }
   },
   methods:{
     faceLoginChange(){
         this.faceLoginState = !this.faceLoginState
         if (!this.faceLoginState){
             // 人脸登录模式 
             if (this.faceInitState){
                 this.$faceEffet.restart()
                 return;
             }
             this.faceInit()
             this.faceInitState = true // 标记已经初始化
         }else {
             // 密码登录模式
         }
     },
     faceInit(){
        this.$faceEffet.init({
            el:this.$refs.faceVueTest, // 直接传入html元素 也可以直接传入字符 'faceId'
            callBack:this.callBack // 阶段回调函数，会打印每个执行步骤，一般是在这个方法调用后端接口
        })
     },
     callBack(data){
         if (data.progressMessage === 'success'){ // 判断阶段是否成功
                let array = data.base64Array; 
                if (!data.base64Array || data.base64Array.length === 0){
                    return;
                }
                Promise.all(data.base64Array).then((base64Strings) => {
                    // base64Strings 是一个人脸图片数组
                    // 这个是模拟请求后端接口，请切换你自己的请求接口
                    http.post('/login/face',{base:base64Strings}).then(res=>{
                         if (res.code === 200){
                             // 登录成功
                             // 这里登录成功应该把后端token带过来存入前端，
                         }else {
                             // 登录失败 重启人脸容器，开始新的一轮验证
                             this.$faceEffet.restart()
                         }
                    })
                }).catch((error) => {
                    console.error("Error resolving promises:", error);
                });
         }        
     }
   }
 }
 </script> 
`

export default faceLoginCode
