let faceLoginCode = `<template>
 <div>
   <div ref="faceVueTest" id="faceId" style="width: 500px;height: 500px;"></div>
   <el-button @click="startFace">开启人脸</el-button>
   <el-button @click="restartFace">重启人脸</el-button>
   <el-button @click="closeFace">关闭人脸</el-button>
 </div>
 </template>
  
 <script>
 // $faceEffet 对象是在main.js 注册好的全局对象
 export default {
   name: "index",
   data(){
     return {
     }
   },
   beforeDestroy(){
     if (this.$faceEffet){
       this.$faceEffet.close();
     }
   },
   methods:{
     startFace(){
         // 人脸容器的初始化
       this.$faceEffet.init({
         el:this.$refs.faceVueTest, // 直接传入html元素 也可以直接传入字符 'faceId'
         callBack:this.callBack, // 阶段回调函数，会打印每个执行步骤，一般是在这个方法调用后端接口
         type:'checkSleep', // 人脸模式，默认是人脸登录
         face:{ 
              maxNumFaces:1, //最大检测到的人脸数，如果选择的是 ”人脸登录“ 或者 ”人脸添加“ 模式，则当前值强制为 1
              refineLandmarks:true, // 是否精细化人脸特征点检测
              minDetectionConfidence:0.5, // 最小检测置信度
              minTrackingConfidence:0.5 // 最小跟踪置信度
         },
          /**
          * @param appData 当前框架内置对象所有属性值
          * @param currentObj 当前对象
          * @param callBackResult 回调函数
          * @param stopRecording 停止录制
          * @param startRecording 重新开始录制，可以不用调用，可以直接调用effet.restart方法
          */
         before:(appData,currentObj,callBackResult,stopRecording,startRecording)=>{ // 人脸框架执行之前的事件，如果当前方法不为空，则覆盖框架内部before方法
             // 放出当前方法，默认会覆盖框架自带before方法
         },
         //action:(appData,currentObj,callBackResult,stopRecording,startRecording)=>{ // 人脸框架执行之前的事件，如果当前方法不为空，则覆盖框架内部before方法
             // 放出当前方法，默认会覆盖框架自带before方法
         //},
         faceStyle:{
             faceColor: { // 整个人脸网格颜色
                 color:'#21d86e', // 线条颜色
                 line:1 // 线条粗度
             },
             rightEye:{ // 右眼颜色
                 color:'#297ae9',
                 line:2,
             },
             rightEyebrow:{ // 右眉毛
                 color:'#297ae9',
                 line:2,
             },
             rightIris:{ // 右眼珠
                 color: 'red',
                 line:1
             },
             leftEye:{ // 左眼颜色
                 color: 'red',
                 line:1
             },
             leftEyebrow:{ // 左眉毛
                 color: 'blue',
                 line:1
             },
             leftIris:{ // 左眼珠
                 color: 'blue',
                 line:1
             },
             oval:{ // 脸型最外层轮廓颜色
                 color: 'blue',
                 line:1
             },
             lips:{ // 嘴唇颜色
                 color: 'red',
                 line:1
             }
         },
         sleepEarThreshold:0.2, // 睡眠检测，当眼睛闭眼的阈值
         sleepTime:2, // 睡眠检测，单位秒，当闭眼多久则判断是在睡眠
         sleepContinuousPush:false //当前检测到睡眠，是否持续推送消息，默认为false,只推送一次
       })
     },
     callBack(data){
         // 验证过程的回调打印
       console.log(data)
     },
     restartFace(){
         // 重启人脸登录
       this.$faceEffet.restart()
     },
     closeFace(){
         // 关闭人脸登录
       this.$faceEffet.close();
     }
   }
 }
 </script> 
`

export default faceLoginCode
