<template>
<div>

  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">参数应用范围解释</span></h3>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px;">
    <el-col :span="24">
      <span class="introduce-character-text">
       <el-tag size="small" type="info" color="#84e0f9">ALL</el-tag> 代表所有模式都可以应用当前参数<br/>
       <el-tag size="small" type="info" color="#84e0f9">人脸登录</el-tag> 当前参数只针对于 <b>人脸登录</b> 有效<br/>
       <el-tag size="small" type="info" color="#84e0f9">人脸打卡</el-tag> 当前参数只针对于 <b>人脸打卡</b> 有效<br/>
       <el-tag size="small" type="info" color="#84e0f9">睡眠检测</el-tag> 当前参数只针对于 <b>睡眠检测</b> 有效<br/>
        <el-tag size="small" type="info" color="#84e0f9">人脸添加</el-tag> 当前参数只针对于 <b>人脸添加</b> 有效<br/>
      </span>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px;">
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">入参对象值</span></h3>
    </el-col>
  </el-row>


  <el-row style="margin-top: 20px;">
    <el-col :span="24">
      <span class="introduce-character-text">
        <el-table
            :data="parameterMain.parameterMain"
            style="width: 100%">
          <el-table-column
              label="名称" width="110"
              prop="name">
          </el-table-column>
          <el-table-column
              label="类型" width="180"
              prop="type">
          </el-table-column>
          <el-table-column
              label="应用范围" width="80"
              prop="use">
          </el-table-column>
          <el-table-column
              label="默认值" width="100"
              prop="default">
            <template slot-scope="props">
              <div v-if="props.row.defState">
                <a :href="'#'+props.row.defSkip" style="color: #8fe8fd">查看详细</a>
              </div>
              <div v-else>
                {{props.row.default}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="是否必填" width="80"
              prop="required">
          </el-table-column>
          <el-table-column
              label="描述"
              prop="desc">
            <template slot-scope="props">
              <div v-if="props.row.childTable">
                {{props.row.desc}}
                <a :href="'#'+props.row.childSkip" style="color: #8fe8fd;margin-left: 10px;">查看详细</a>
              </div>
              <div v-else>
                {{props.row.desc}}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </span>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px;">
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">
        <a id="face-parameters-def"></a>
        face 参数默认值
      </span></h3>
    </el-col>
  </el-row>

  <el-row>
    <el-col :span="24">
      <div v-highlight>
          <pre>
            <code class="javascript">{
  maxNumFaces:1,
  refineLandmarks:true,
  minDetectionConfidence:0.5,
  minTrackingConfidence:0.5
}</code>
          </pre>
      </div>
    </el-col>
  </el-row>


  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">
        <a id="face-parameters"></a>
        face 参数详细说明
      </span></h3>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px;">
    <el-col :span="24">
      <span class="introduce-character-text">
        <el-table
            :data="parameterMain.face"

            style="width: 100%">
          <el-table-column
              label="名称" width="210"
              prop="name">
          </el-table-column>
          <el-table-column
              label="类型" width="180"
              prop="type">
          </el-table-column>
          <el-table-column
              label="应用范围" width="80"
              prop="use">
          </el-table-column>
          <el-table-column
              label="默认值" width="100"
              prop="default">
            <template slot-scope="props">
              <div v-if="props.row.defState">
                <a href="#face-parameters-def" style="color: #8fe8fd">查看详细</a>
              </div>
              <div v-else >
                {{props.row.default}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="是否必填" width="80"
              prop="required">
          </el-table-column>
          <el-table-column
              label="描述"
              prop="desc">
            <template slot-scope="props">
              <div v-if="props.row.childTable">
                {{props.row.desc}}
                <a href="#face-parameters" style="color: #8fe8fd;margin-left: 10px;">查看详细</a>
              </div>
              <div v-else >
                  {{props.row.desc}}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </span>
    </el-col>
  </el-row>


  <el-row style="margin-top: 20px;">
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">
        <a id="face-parameters-before"></a>
        before 使用方式
      </span></h3>
    </el-col>
  </el-row>

  <el-row>
    <el-col :span="24">
      <div v-highlight>
          <pre>
            <code class="javascript">{{parameterMain.beforeCode}}</code>
          </pre>
      </div>
    </el-col>
  </el-row>


  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">
        <a id="face-parameters-action"></a>
        action 使用方式
      </span></h3>
    </el-col>
  </el-row>

  <el-row>
    <el-col :span="24">
      <div v-highlight>
          <pre>
            <code class="javascript">{{parameterMain.actionCode}}</code>
          </pre>
      </div>
    </el-col>
  </el-row>

  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">
        <a id="face-parameters-face-style"></a>
        faceStyle 参数详细使用
      </span></h3>

      <span class="introduce-character-text">faceStyle对象本身是非必填的，以及它下面的子对象也是非必填，填了就应用对应的颜色</span>
    </el-col>
  </el-row>

  <el-row>
    <el-col :span="24">
      <div v-highlight>
          <pre>
            <code class="javascript">{{parameterMain.faceStyle}}</code>
          </pre>
      </div>
    </el-col>
  </el-row>






  <el-row style="margin-top: 20px;">
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">
        <a id="face-parameters-callBack"></a>
        callBack 函数返回详细
      </span></h3>
    </el-col>
  </el-row>

  <el-row>
    <el-col :span="24">
      <div v-highlight>
          <pre>
            <code class="javascript">  effet.init({
    el: 'myface',
    callBack: (data) => {
        // 当前打印每一步进行的消息，具体可查看下面参数解释
        console.log(data);
    }
});</code>
          </pre>
      </div>
    </el-col>
  </el-row>

  <el-row >
    <el-col :span="24">
      <span class="introduce-character-text">
        <el-table
            :data="parameterMain.callBack"
            style="width: 100%">
          <el-table-column
              label="名称" width="200"
              prop="name">
          </el-table-column>
          <el-table-column
              label="类型" width="180"
              prop="type">
          </el-table-column>
          <el-table-column
              label="应用范围" width="80"
              prop="use">
          </el-table-column>
          <el-table-column
              label="默认值" width="100"
              prop="default">
            <template slot-scope="props">
              <div v-if="props.row.defState">
                <a href="#face-parameters-def" style="color: #8fe8fd">查看详细</a>
              </div>
              <div v-else >
                {{props.row.default}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="是否必填" width="80"
              prop="required">
          </el-table-column>
          <el-table-column
              label="描述"
              prop="desc">
            <template slot-scope="props">
              <div v-if="props.row.childTable">
                {{props.row.desc}}
                <a href="#face-parameters" style="color: #8fe8fd;margin-left: 10px;">查看详细</a>
              </div>
              <div v-else >
                  {{props.row.desc}}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </span>
    </el-col>
  </el-row>

</div>
</template>

<script>
import parameterMain from "@/views/V1.0.0/views/parameter-set/table/parameter-main";
export default {
  name: "index",
  data(){
    return{
      parameterMain
    }
  },
  components:{
  }
}
</script>

<style>

</style>
