
let vueMain = `
<template>
<div>
  <div id="faceVue"></div>
  <el-button @click="startFace">开启人脸</el-button>
  <el-button @click="restartFace">重启人脸</el-button>
  <el-button @click="closeFace">关闭人脸</el-button>
</div>
</template>

<script>
// 静态资源的调用方式，将打包好的资源放在你的assets或者其他文件夹
import effet from "@/views/V1.0.0/views/index";
export default {
  name: "index",
  data(){
    return {
      faceEffet:effet,
    }
  },
  beforeDestroy(){
    if (this.faceEffet){
      this.faceEffet.close();
    }
  },
  methods:{
    startFace(){
      this.faceEffet.init({
        el:'faceVue',
        callBack:this.callBack
      })
    },
    callBack(data){
      console.log(data)
    },
    restartFace(){
      this.faceEffet.restart()
    },
    closeFace(){
      this.faceEffet.close();
    }
  }
}
</script>

<style>
/*静态资源的调用方式，将打包好的资源放在你的assets或者其他文件夹*/
@import "../effet.css";
</style>

`

export default {
    vueMain
}
