const getCode = (type,styleState)=>{
let style = styleState ? 'style="width: 500px;height: 500px;"' : ''
return `<template>
 <div>
   <div ref="faceVueTest" id="faceId" ${style}></div>
   <el-button @click="startFace">开启人脸</el-button>
   <el-button @click="restartFace">重启人脸</el-button>
   <el-button @click="closeFace">关闭人脸</el-button>
 </div>
 </template>
  
 <script>
 // $faceEffet 对象是在main.js 注册好的全局对象
 export default {
   name: "index",
   data(){
     return {
     }
   },
   beforeDestroy(){
     if (this.$faceEffet){
       this.$faceEffet.close();
     }
   },
   methods:{
     startFace(){
         // 人脸容器的初始化
       this.$faceEffet.init({
         el:this.$refs.faceVueTest, // 直接传入html元素 也可以直接传入字符 'faceId'
         type:'${type}', 
         callBack:this.callBack // 阶段回调函数，会打印每个执行步骤，一般是在这个方法调用后端接口
       })
     },
     callBack(data){
         // 验证过程的回调打印
       console.log(data)
     },
     restartFace(){
         // 重启人脸容器
       this.$faceEffet.restart()
     },
     closeFace(){
         // 关闭人脸容器
       this.$faceEffet.close();
     }
   }
 }
 </script> 
`}

export {getCode}
