<template>
<div>
  <iframe height="450px" width="100%" src="https://form.antdv.com/r/668a56b4fc400040" frameborder="0" allowfullscreen></iframe>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>
