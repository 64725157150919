<template>
<div>

  <div class="effet-panel">
    🌟 点击开启{{modelText}}，说明你已阅读使用声明❗<br/>
  </div>


  <div class="effet-panel">
    ❓什么是自定义样式？ 自定义样式可以不依赖框架内部样式，自动手写一个人脸样式出来
  </div>

  <el-row >
    <el-col :span="24">
      <span class="introduce-character-text">
        <b>初次开启会有延迟，耐心等待</b>，请同意浏览器获取摄像头权限，
        具体回调，请查看浏览器Console的打印日志</span>
    </el-col>
  </el-row>

  <el-row >
    <el-col :span="24">
      <span class="introduce-character-text">
        我们现在已<b>人脸登录</b>作为示例
      </span>
    </el-col>
  </el-row>

  <el-row style="margin-top: 30px;">
    <el-col :span="24" style="width:100%;text-align: center;position: relative;">

      <div v-if="shelterState" class="shelter" @click="open"></div>
      <input id="checkbox" type="checkbox" @change="startAndClose"  />
      <label class="switch-face-login" for="checkbox">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="slider">
          <path
              d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"
          ></path>
        </svg>
      </label>
      <span class="introduce-character-text">点击{{faceState?'关闭':'开启'}}{{modelText}}</span>

    </el-col>
  </el-row>

  <el-row style="margin-top: 30px; margin-bottom: 20px">
    <el-col :span="24" >
      <div class="login-body" ref="loginBody" v-if="faceState">
        <img class="currentImg" :src="backgroundImage" alt="Background Image" />
        <div class="tips">{{ tips }}</div>
        <div class="step">
          <div v-for="(step,index) in steps" :key="step" :ref="'stepItem'+step" class="step-item">{{ index+1 }}</div>
        </div>
      </div>
    </el-col>
  </el-row>


  <el-row >
    <el-col :span="11"  class="show-code no-select" ref="showCode">
        <img src="~@/assets/sx.png" @click="codeShow(0)">
          <span class="introduce-character-text" @click="codeShow(0)">
        {{ showCode ? '收起Vue代码' : '展开Vue代码' }}
      </span>
    </el-col>

    <el-col :span="11" :offset="2" class="show-code no-select" ref="showCode">
      <img src="~@/assets/sx.png" @click="codeShow(1)">
      <span class="introduce-character-text" @click="codeShow(1)">
        {{ detailShowCode ? '收起h5代码' : '展开h5代码' }}
      </span>
    </el-col>

  </el-row>


  <transition name="el-zoom-in-top">
    <el-row :gutter="30" v-show="showCode">
      <el-col :span="24">
        <div v-highlight>
        <pre>
          <code class="html">{{vueCode}}</code>
        </pre>
        </div>
      </el-col>
    </el-row>
  </transition>


  <transition name="el-zoom-in-top">
    <el-row :gutter="30" v-show="detailShowCode">
      <el-col :span="24">
        <div v-highlight>
        <pre>
          <code class="html">{{h5Code}}</code>
        </pre>
        </div>
      </el-col>
    </el-row>
  </transition>




</div>
</template>

<script>
import vueCode from "./vueCode";
import h5Code from "./h5Code";
export default {
  name: "index",
  data(){
    return {
      faceState:false,
      showCode:false,
      detailShowCode:false,
      vueCode:vueCode,
      h5Code:h5Code,
      originalPosition: 0,
      modelText:'自定义样式',
      shelterState:true,
      tips: '',
      steps: [2, 4, 6],
      backgroundImage: 'https://pic.imgdb.cn/item/670f8837d29ded1a8c19d352.webp',
    }
  },
  mounted() {

  },
  beforeDestroy(){
    if (this.$faceEffet){
      this.$faceEffet.close();
    }
  },
  created() {
    this.shelterState = localStorage.getItem('shelterState') == null ? true : JSON.parse(localStorage.getItem('shelterState'))
  },
  methods:{
    codeShow(num){
      if (num === 0){
        this.detailShowCode = false
        this.showCode = !this.showCode
      }else {
        this.showCode = false
        this.detailShowCode = !this.detailShowCode
      }
    },
    callBack(data){
      console.log(data)
      if ([2, 4, 6].includes(data.step)) {
        console.log(data.progressMessage);
        this.tips = data.progressMessage === 'success' ? '正在验证人脸...' : data.progressMessage;
        const stepItem = this.$refs["stepItem"+data.step]?.[0];
        stepItem?.classList.add('step-color-selected');
      }
      if (data.progressMessage === 'success') {
        Promise.all(data.base64Array)
            .then((base64Strings) => {
              console.log(base64Strings);
            })
            .catch((error) => {
              console.error('Error resolving promises:', error);
            });
      }
    },
    open(){
      this.$alert('我已阅读，并详细了解Face-Effet.js人脸框架的使用声明', '使用提示', {
        confirmButtonText: '已阅读',
        cancelButtonText:'取消',
        showCancelButton:true,
        callback: action => {
          // confirm
          if ('confirm' === action){
            this.shelterState = false
            localStorage.setItem('shelterState',this.shelterState)
            const checkbox = document.getElementById('checkbox');
            checkbox.checked = !checkbox.checked;
            this.startAndClose()
          }
        }
      });
    },
    startAndClose(){
      this.faceState = !this.faceState
      if (this.faceState){
        this.$nextTick(()=>{
          this.$faceEffet.init({
            el:this.$refs.loginBody,
            appearance: false, // 关闭原框架样式！！
            callBack:this.callBack,
          })
        })
      }else {
        this.tips = ''
        this.$refs.stepItem2[0]?.classList.remove('step-color-selected')
        this.$refs.stepItem4[0]?.classList.remove('step-color-selected')
        this.$refs.stepItem6[0]?.classList.remove('step-color-selected')
        this.$faceEffet?.close();
      }
    }
  }
}
</script>
<style>
@import "../index.css";
</style>

<style>
.login-body {
  width: 300px;
  height: 500px;
  position: relative;
  overflow: hidden;
  border: 1px solid #999;
  margin: 0 auto;
}
.currentImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
}
.step,
.tips {
  width: 120px;
  height: 20px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 2;
}
.tips {
  bottom: 15%;
  font-size: 20px;
  text-align: center;
  color: #fff;
}
.step {
  bottom: 8%;
  display: flex;
  justify-content: space-between;
}
.step-item {
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #999;
}
.step-color-selected {
  border: 1px solid #fff !important;
  color: #fff !important;
}
</style>
