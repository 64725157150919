<template>
<div>

  <div class="effet-panel">
    🌟 点击开启{{modelText}}，说明你已阅读使用声明❗
  </div>
  <el-row >
    <el-col :span="24">
      <span class="introduce-character-text">
        <b>初次开启会有延迟，耐心等待</b>，请同意浏览器获取摄像头权限，
        具体回调，请查看浏览器Console的打印日志</span>
    </el-col>
  </el-row>


  <el-row style="margin-top: 30px;">
    <el-col :span="24" style="width:100%;text-align: center;position: relative;">

      <div v-if="shelterState" class="shelter" @click="open"></div>
      <input id="checkbox" type="checkbox" @change="startAndClose"  />
      <label class="switch-face-login" for="checkbox">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="slider">
          <path
              d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"
          ></path>
        </svg>
      </label>
      <span class="introduce-character-text">点击{{faceState?'关闭':'开启'}}{{modelText}}</span>

    </el-col>
  </el-row>

  <el-row style="margin-top: 30px; margin-bottom: 20px">
    <el-col :span="24" >
      <div ref="faceTest" id="faceH5" v-if="faceState"></div>
    </el-col>
  </el-row>


  <el-row >
    <el-col :span="11"  class="show-code no-select" ref="showCode">
        <img src="~@/assets/sx.png" @click="codeShow(0)">
          <span class="introduce-character-text" @click="codeShow(0)">
        {{ showCode ? '收起简约代码' : '展开简约代码' }}
      </span>
    </el-col>

    <el-col :span="11" :offset="2" class="show-code no-select" ref="showCode">
      <img src="~@/assets/sx.png" @click="codeShow(1)">
      <span class="introduce-character-text" @click="codeShow(1)">
        {{ detailShowCode ? '收起详细代码' : '展开详细代码' }}
      </span>
    </el-col>

  </el-row>


  <transition name="el-zoom-in-top">
    <el-row :gutter="30" v-show="showCode">
      <el-col :span="24">
        <div v-highlight>
        <pre>
          <code class="html">{{simpleCode}}</code>
        </pre>
        </div>
      </el-col>
    </el-row>
  </transition>


  <transition name="el-zoom-in-top">
    <el-row :gutter="30" v-show="detailShowCode">
      <el-col :span="24">
        <div v-highlight>
        <pre>
          <code class="html">{{detailCode}}</code>
        </pre>
        </div>
      </el-col>
    </el-row>
  </transition>


  <el-row style="margin-top: 40px;">
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">Face Options</span></h3>
    </el-col>
  </el-row>

  <el-row style="margin-top: 30px;">
    <el-col :span="24">
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            prop="parameter"
            label="参数">
        </el-table-column>
        <el-table-column
            prop="desc"
            label="说明">
        </el-table-column>
        <el-table-column
            prop="type"
            label="类型">
        </el-table-column>
        <el-table-column
            prop="values"
            label="可选值">
        </el-table-column>
        <el-table-column
            prop="default"
            label="默认值">
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>



</div>
</template>

<script>
import detailCode from "./detailCode";
import {getCode} from "@/views/components/simpleCode";
import {getTableDataByType} from "@/views/components/table";
export default {
  name: "index",
  data(){
    return {
      faceState:false,
      showCode:false,
      detailShowCode:false,
      simpleCode:getCode('clockIn',true),
      detailCode:detailCode,
      originalPosition: 0,
      tableData:getTableDataByType('facePunch'),
      modelText:'人脸打卡',
      shelterState:true
    }
  },
  mounted() {

  },
  beforeDestroy(){
    if (this.$faceEffet){
      this.$faceEffet.close();
    }
  },
  created() {
    this.shelterState = localStorage.getItem('shelterState') == null ? true : JSON.parse(localStorage.getItem('shelterState'))
  },
  methods:{
    codeShow(num){
      if (num === 0){
        this.detailShowCode = false
        this.showCode = !this.showCode
      }else {
        this.showCode = false
        this.detailShowCode = !this.detailShowCode
      }
    },
    callBack(data){
      console.log(data)
    },
    open(){
      this.$alert('我已阅读，并详细了解Face-Effet.js人脸框架的使用声明', '使用提示', {
        confirmButtonText: '已阅读',
        cancelButtonText:'取消',
        showCancelButton:true,
        callback: action => {
          // confirm
          if ('confirm' === action){
            this.shelterState = false
            localStorage.setItem('shelterState',this.shelterState)
            const checkbox = document.getElementById('checkbox');
            checkbox.checked = !checkbox.checked;
            this.startAndClose()
          }
        }
      });
    },
    startAndClose(){
      this.faceState = !this.faceState
      if (this.faceState){
        this.$nextTick(()=>{
          this.$faceEffet.init({
            el:this.$refs.faceTest,
            type:'clockIn',
            callBack:this.callBack,
          })
        })
      }else {
        this.$faceEffet.close();
      }
    }
  }
}
</script>

<style>
@import "../index.css";
</style>
