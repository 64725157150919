<template>
<div>

  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">Effet对象主要入口函数</span></h3>
    </el-col>
  </el-row>

  <el-row>
    <el-col :span="24">
      <h4><span class="introduce-character-text-title">init函数</span></h4>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24" >

      <el-row >
        <el-col :span="24">
          <span class="introduce-character-text"><el-tag size="small" type="info" color="#84e0f9">init</el-tag> 初始化函数用于程序启动时的整体初始化操作，其具体入参请参考参数集合项。在此阶段，主要执行以下任务</span>
        </el-col>
      </el-row>

      <el-row style="margin-top: 20px">
        <el-col :span="24" >
          <ul class="introduce-character">
            <li>查找并设置当前页面元素</li>
            <li>进行赋值与覆盖操作</li>
            <li>创建 DOM 元素</li>
            <li>启动摄像头</li>
            <li>缓存资源文件</li>
            <li>执行 before 函数</li>
            <li>检测人脸点位信息</li>
            <li>调用 action 函数</li>
            <li>执行回调方法</li>
          </ul>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div v-highlight>
          <pre>
            <code class="javascript">{{init}}</code>
          </pre>
          </div>
        </el-col>
      </el-row>

    </el-col>
  </el-row>


  <el-row>
    <el-col :span="24">
      <h4><span class="introduce-character-text-title">close函数</span></h4>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24" >

      <el-row >
        <el-col :span="24">
          <span class="introduce-character-text"><el-tag size="small" type="info" color="#84e0f9">close</el-tag> 是关闭当前正在运行中的人脸，会存在一点延迟，在我们当前页面销毁的时候，也应该调用当前函数</span>
        </el-col>
      </el-row>


      <el-row>
        <el-col :span="24">
          <div v-highlight>
          <pre>
            <code class="javascript">{{close}}</code>
          </pre>
          </div>
        </el-col>
      </el-row>


      <el-row >
        <el-col :span="24">
          <span class="introduce-character-text">页面销毁的时候</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div v-highlight>
          <pre>
            <code class="javascript">{{beforeDestroyFace}}</code>
          </pre>
          </div>
        </el-col>
      </el-row>

    </el-col>
  </el-row>


  <el-row>
    <el-col :span="24">
      <h4><span class="introduce-character-text-title">restart函数</span></h4>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24" >

      <el-row >
        <el-col :span="24">
          <span class="introduce-character-text"><el-tag size="small" type="info" color="#84e0f9">restart</el-tag> 会重启当前人脸容器，注意的是，restart重启会重置内部的数据，但不会重置dom元素，
          如果需要重新调用人脸服务，建议是调用restart函数，而不是init函数
          </span>
        </el-col>
      </el-row>


      <el-row>
        <el-col :span="24">
          <div v-highlight>
          <pre>
            <code class="javascript">{{restart}}</code>
          </pre>
          </div>
        </el-col>
      </el-row>


      <el-row >
        <el-col :span="24">
          <span class="introduce-character-text">注意的是，restart可以传入参数进入，不支持el参数的传入，其他参数可以，这样会覆盖之前的blur值</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div v-highlight>
          <pre>
            <code class="javascript">{{spread}}</code>
          </pre>
          </div>
        </el-col>
      </el-row>

    </el-col>
  </el-row>

  <el-row>
    <el-col :span="24">
      <h4><span class="introduce-character-text-title">cache函数</span></h4>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px">
    <el-col :span="24" >

      <el-row >
        <el-col :span="24">
          <span class="introduce-character-text"><el-tag size="small" type="info" color="#84e0f9">cache</el-tag> 在当前页面刚刚加载的时候，可以先进行缓存操作，因为在init函数也会调用当前cache函数
          ，如果已缓存，则会忽略掉，所以不必担心重复缓存的问题，从而调用init函数是不需要缓存而提升人脸容器加载速度
          </span>
        </el-col>
      </el-row>


      <el-row>
        <el-col :span="24">
          <div v-highlight>
          <pre>
            <code class="javascript">{{cache}}</code>
          </pre>
          </div>
        </el-col>
      </el-row>

    </el-col>
  </el-row>


</div>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
      init:`effet.init({
   el: 'dangqface',
   callBack: (data) => {
     console.log(data);
   }
 })`,
      close:`effet.close()`,
      beforeDestroyFace:`beforeDestroy(){
    if (this.effet){
      this.effet.close();
    }
  }`,
      restart:`effet.restart()`,
      spread:`effet.restart({
  blur:12
  })`,
      cache:`effet.cache()`
    }
  }
}
</script>

<style scoped>

</style>
