let userFaceHtml = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Face-effet.js 人脸登录示例</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="https://unpkg.com/face-effet/effet/effet.css">
    <script src="https://unpkg.com/face-effet/effet/effet.js"></script>
    <style>
        #myface{
            margin-top: 350px;
        }
    </style>
    <script>
        document.addEventListener("DOMContentLoaded", function() {
            effet.init({
                el: 'myface',
                callBack: (data) => {
                    console.log(data);
                }
            });
        });
    </script>
</head>
<body>
<!-- 用于渲染人脸识别的容器 -->
<div id="myface"></div>
<button onclick="effet.restart()">重新检测</button>
</body>
</html>`
export default userFaceHtml
