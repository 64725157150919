import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import 'face-effet/effet/effet.css'
import faceEffet from 'face-effet/effet/effet.js'

Vue.prototype.$faceEffet = faceEffet

import hljs from 'highlight.js/lib/core'; // 只引入核心模块

// 引入需要的语言支持
import javascript from 'highlight.js/lib/languages/javascript';
import css from 'highlight.js/lib/languages/css';
import html from 'highlight.js/lib/languages/xml'; // HTML 属于 XML 模块

// 注册语言到 hljs
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('css', css);
hljs.registerLanguage('html', html);



// 引入高亮样式
import 'highlight.js/styles/atom-one-light.css';

// 添加行号功能
function addLineNumbers(block) {
  if (block.getAttribute('data-has-lines')) {
    return; // 如果已经有行号，则不再添加
  }

  const lines = block.innerHTML.split('\n');
  const numberedLines = lines.map((line, index) => {
    return `<span class="hljs-line-number noselect">${index + 1}</span> ${line}`;
  }).join('\n');
  block.innerHTML = `<span class="hljs-code-block">${numberedLines}</span>`;

  // 标记此代码块已经添加了行号
  block.setAttribute('data-has-lines', 'true');
}

// 定义全局指令
Vue.directive('highlight', {
  // 当元素插入 DOM 时调用
  inserted(el) {
    const blocks = el.querySelectorAll('pre code');
    blocks.forEach(block => {
      hljs.highlightElement(block); // 使用 highlightElement 而不是 highlightBlock
      addLineNumbers(block);
    });
  },
  // 当组件更新时调用
  componentUpdated(el) {
    const blocks = el.querySelectorAll('pre code');
    blocks.forEach(block => {
      hljs.highlightElement(block); // 重新高亮
      addLineNumbers(block);
    });
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
