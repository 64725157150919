<template>
<div style="margin-top: 50px;margin-bottom: 30px;padding-right: 30px;">
  <el-row v-if="routeObj">
    <el-col :span="12">
      <button  v-if="routeObj.lastName != null" class="cssbuttons-io-button-left cssbuttons-io-button" @click="toPage(routeObj.last)">
        <div class="icon">
          <svg
              height="24"
              width="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path
                d="M7.828 13l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414L7.828 11H20v2z"
                fill="currentColor"
            ></path>
          </svg>
        </div>
        {{routeObj.lastName}}
      </button>
      <span v-else>&nbsp;</span>

    </el-col>
    <el-col v-if="routeObj.nextName != null" :span="12" style="display: flex;justify-content: right;">
      <button class="cssbuttons-io-button-right cssbuttons-io-button" @click="toPage(routeObj.next)">
        {{routeObj.nextName}}
        <div class="icon">
          <svg
              height="24"
              width="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path
                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                fill="currentColor"
            ></path>
          </svg>
        </div>
      </button>

    </el-col>
  </el-row>
</div>
</template>

<script>
const sidebar = require('../../V1.0.0/sidebar')
export default {
  name: "index",
  data(){
    return{
      routeObj:null
    }
  },
  mounted() {
    this.routeObj = this.getNavInfo(sidebar.list,this.$route.path)
  },
  methods:{
    toPage(path) {
      this.$router.push(path)
      this.routeObj = this.getNavInfo(sidebar.list,this.$route.path)
      this.$emit('cutChange',path)
    },
    getNavInfo(arr,path) {
      let flattenedArr = arr.reduce((acc, current) => {
        return acc.concat(current.child);
      }, []);
      let currentIndex = flattenedArr.findIndex(item => item.path === path);
      let result = {
        last: null,
        lastName: null,
        next: null,
        nextName: null
      };
      if (currentIndex > 0) {
        result.last = flattenedArr[currentIndex - 1].path;
        result.lastName = flattenedArr[currentIndex - 1].name;
      }
      if (currentIndex < flattenedArr.length - 1) {
        result.next = flattenedArr[currentIndex + 1].path;
        result.nextName = flattenedArr[currentIndex + 1].name;
      }
      return result;
    },
  }
}
</script>

<style >
@import "./index.css";
</style>
