<template>
<div>
  <el-row>
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">常见问题</span></h3>
    </el-col>
  </el-row>

  <el-row style="margin-top: 20px;">
    <el-col :span="24">
      <el-collapse >
        <el-collapse-item title="报错：Element not provided. Please pass a valid DOM element to initialize effet." name="1">
          <div>当前effet.init方法中必须传入一个对象，当前对象不能为空，或者当前参数：`el` 不是一个有效的
          html元素，或者说不是一个有效的字符串，你需要确认当前页面是否存在该元素，一般传入class或者id，或者通过vue的refs拿到元素直接传过来</div>
        </el-collapse-item>
        <el-collapse-item title="报错：初始化失败" name="2">
          <div>在框架刚刚加载之前，会主动询问浏览器的摄像头权限，你需要点击同意，
          如果碰见并没有出现弹窗询问的情况，可能因为浏览器默认把弹窗给拦截了</div>
        </el-collapse-item>
        <el-collapse-item title="报错：Please complete the call to 'init' before invoking the restart task" name="3">
          <div>在调用effet.restart方法的时候，一般先去调用init方法进行初始化，后面才可以去调用restart方法</div>
        </el-collapse-item>
        <el-collapse-item title="为什么没有出现摄像头弹窗询问？" name="4">
          <div>
            在你自己的项目部署在线上的时候，确保你的域名是<b>https</b>
            而不是http, 如果是http则会被浏览器当作不安全的环境，会被拦截，本地环境不受影响
          </div>
        </el-collapse-item>
        <el-collapse-item title="怎么调用后端接口，什么时候去调用" name="5">
          <div>调用后端接口一般都在callBack方法中调用，具体可查看参数集合查看callBack具体的返回值</div>
        </el-collapse-item>
        <el-collapse-item title="为什么人脸图片上面有一串密钥" name="6">
          <div>一般callBack会返回一个secretKey字段，这个密钥是跟图片上的密钥相等的，可以用作与后端对图片进行裁剪，
          然后通过 OCR 提取文字出来跟secretKey进行对比，主要防止一个接口被盗刷的问题</div>
        </el-collapse-item>
        <el-collapse-item title="人脸登录，人脸打卡，睡眠检测的区别是什么" name="7">
          <div>人脸登录：主要用于pc端或者手机端进行人脸登录，前端会进行校验动作，比如眨眨眼，张张嘴，左右摇头</div>
          <div>人脸打卡：可实现人脸进行打卡，可输入参数人脸的打卡距离，完成打卡操作</div>
          <div>睡眠检测：当人脸的验证闭上则会触发当前事件</div>
        </el-collapse-item>
        <el-collapse-item title="Effet.js存在的意义？" name="8">
          <div>Effet.js是一款轻量级人脸样式框架，可以快速帮我搭建前端识别校验的一个操作，这不是最终的校验，
          需要配合后端一起校验，简单操作的api，方便快速帮我们搭建人脸登录，后端我们只需要调用其他厂商的接口
          </div>
          <div>比如：</div>
          <div>虹软人脸识别</div>
          <div>百度云人脸识别</div>
          <div>阿里云人脸识别</div>
          <div>腾讯云人脸识别</div>
          <div>等等....</div>
        </el-collapse-item>

        <el-collapse-item title="Camera access is not supported in the current environment. Please use HTTPS or a local development environment." name="9">
          <div>说明当前环境不支持摄像头，摄像头的开启只支持本地环境，或者https环境，这个问题是由于在 HTTP 环境下使用摄像头被浏览器限制导致的。现代浏览器（如 Chrome、Firefox 等）
            为了用户的安全隐私，要求摄像头和麦克风只能在安全环境中被访问，也就是使用 HTTPS 协议的网站。因为 HTTP 没有提供足够的安全保护，浏览器会阻止不安全环境下的摄像头访问请求。</div>
        </el-collapse-item>
      </el-collapse>
    </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>
