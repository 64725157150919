<template>

  <div>

    <div class="brief-main">
      <div class="brief-main-title">
        简介
        </div>
      <div class="brief-main-content">
        Effet.js 是一个轻量级的人脸样式框架，专注于为网页带来生动的面部动画和动态效果。通过简单易用的 API，开发者不仅可以实现眨眼、张嘴、摇头等基础表情，还可以应用于多种场景，如人脸打卡、人脸登录、睡眠监测等。同时，Effet.js 对外提供 API，用户可根据需求灵活定制，实现更多的动作或功能，使得前端应用更加互动、智能且个性化。
      </div>

      <el-row>
        <el-col :span="24" style="margin-left: -5px">
          <div class="logo-effet">
            <div class="logo-main">
              <div class="logo-first-child"></div>
              <div class="logo-first-child-2"></div>
              <div class="logo-first-child-3">
                <div class="logo-main-top-color-left"></div>
                <div class="logo-main-top-color-right"></div>
              </div>
              <div class="logo-first-child-4"></div>
              <div class="logo-first-child-5"></div>
              <div class="logo-first-child-6">
                <div class="logo-main-top-color-left-2"></div>
                <div class="logo-main-top-color-right-2"></div>
              </div>
              <div class="logo-first-child-7"></div>
            </div>
            <div class="logo-left-point-left">
            </div>
            <div class="logo-left-point-right">
            </div>
            <div class="logo-effet-text">Effet.js</div>
          </div>

        </el-col>
      </el-row>
    </div>

  </div>

</template>

<script>
export default {
  name: "index",
  methods:{

  }
}
</script>

<style>
@import "./index.css";

</style>
