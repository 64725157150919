let vueCode = `<template>
  <div>
    <div class="login-body" ref="loginBody">
      <img class="currentImg" :src="backgroundImage" alt="Background Image" />
      <div class="tips">{{ tips }}</div>
      <div class="step">
        <div v-for="(step,index) in steps" :key="step" :ref="'stepItem'+step" class="step-item">{{ index+1 }}</div>
      </div>
    </div>
    <el-button @click="startFace">开启人脸</el-button>
    <el-button @click="closeFace">关闭人脸</el-button>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      tips: '',
      steps: [2, 4, 6],
      backgroundImage: 'https://pic.imgdb.cn/item/670f8837d29ded1a8c19d352.webp',
    };
  },
  beforeDestroy() {
    this.closeFace();
  },
  methods: {
    startFace() {
      this.$faceEffet.init({
        el: this.$refs.loginBody,// 人脸容器
        appearance: false, // 关闭原框架样式！！
        callBack: this.callBack,
      });
    },
    callBack(data) {
      if ([2, 4, 6].includes(data.step)) {
        console.log(data.progressMessage);
        this.tips = data.progressMessage === 'success' ? '正在验证人脸...' : data.progressMessage;
        const stepItem = this.$refs["stepItem"+data.step]?.[0];
        stepItem?.classList.add('step-color-selected');
      }
      if (data.progressMessage === 'success') {
        Promise.all(data.base64Array)
            .then((base64Strings) => {
              console.log(base64Strings);
            })
            .catch((error) => {
              console.error('Error resolving promises:', error);
            });
      }
    },
    closeFace() {
        this.tips = ''
        this.$refs.stepItem2[0]?.classList.remove('step-color-selected')
        this.$refs.stepItem4[0]?.classList.remove('step-color-selected')
        this.$refs.stepItem6[0]?.classList.remove('step-color-selected')
        this.$faceEffet?.close();
    },
  },
};
</script>

<style>
.login-body {
  width: 300px;
  height: 500px;
  position: relative;
  overflow: hidden;
  border: 1px solid #999;
}
.currentImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
}
.step,
.tips {
  width: 120px;
  height: 20px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 2;
}
.tips {
  bottom: 15%;
  font-size: 20px;
  text-align: center;
  color: #fff;
}
.step {
  bottom: 8%;
  display: flex;
  justify-content: space-between;
}
.step-item {
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #999;
}
.step-color-selected {
  border: 1px solid #fff !important;
  color: #fff !important;
}
</style>`

export default vueCode
