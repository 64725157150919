<template>
<div>

  <div class="effet-panel">
    🌟 以下是实战攻略的代码示例。阅读至此，表示你已同意并了解使用声明❗
  </div>
  <el-row >
    <el-col :span="24">
      <span class="introduce-character-text">
        以下代码是在<b>vue</b>中的实战攻略，具体方法可以调整为你自己的方法，下面只是一个代码参考案例 <br/>
        以下示例主要讲解怎么提升<b>加载速度</b>，以及账号密码登录人脸登录的同时存在
      </span>
    </el-col>
  </el-row>


  <el-row style="margin-top: 40px;">
    <el-col :span="24">
      <h3><span class="introduce-character-text-title">实战案例</span></h3>
    </el-col>
  </el-row>

  <transition name="el-zoom-in-top">
    <el-row :gutter="30" >
      <el-col :span="24">
        <div v-highlight>
        <pre>
          <code class="html">{{detailCode}}</code>
        </pre>
        </div>
      </el-col>
    </el-row>
  </transition>


</div>
</template>

<script>
import detailCode from "./detailCode";

export default {
  name: "index",
  data(){
    return {
      faceState:false,
      detailCode:detailCode,
      shelterState:true,
      form:{
        name:"",
        pwd:""
      },
      faceLoginState:true
    }
  },
  methods:{

  }
}
</script>

<style>
@import "../index.css";
</style>
