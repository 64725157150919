<template>

  <div>

    <el-row>
      <el-col :span="24">
        <h3><span class="introduce-character-text-title">安装</span></h3>
      </el-col>
    </el-row>


    <el-row style="margin-top: 20px">
      <el-col :span="24" >

        <el-row >
          <el-col :span="24">
            <span class="introduce-character-text">通过npm的方式安装</span>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <div v-highlight>
          <pre>
            <code class="node">npm i face-effet -S</code>
          </pre>
            </div>
          </el-col>
        </el-row>

      </el-col>
    </el-row>


    <el-row >
      <el-col :span="24">
        <h3><span class="introduce-character-text-title">第三方CDN方式引入</span></h3>
      </el-col>
    </el-row>


    <div class="effet-panel">
      💡 以下列出的都是由知名第三方免费开放的公共 CDN 资源，各版本更新会通过 NPM 或 GitHub 自动进行同步，确保资源的实时更新和可用性，方便开发者快速获取和使用最新的版本。
    </div>


    <el-row>
      <el-col :span="24">
        <div v-highlight>
          <pre>
            <code class="html">&lt;!-- 引入样式 --&gt;
&lt;link rel=&quot;stylesheet&quot; href=&quot;https://unpkg.com/face-effet/effet/effet.css&quot;&gt;
&lt;!-- 引入框架 --&gt;
&lt;script src=&quot;https://unpkg.com/face-effet/effet/effet.js&quot;&gt;&lt;/script&gt;</code>
          </pre>
        </div>
      </el-col>
    </el-row>


    <el-row >
      <el-col :span="24">
        <h3><span class="introduce-character-text-title">使用</span></h3>
      </el-col>
    </el-row>

    <div class="effet-panel">
      👌 只需要简单引入CDN，即可完成对人脸的<span>眨眨眼</span>，<span>张张嘴</span>，<span>左右摇头</span>校验，完成校验后通过callBack回调方法调用后台接口
    </div>


    <el-row>
      <el-col :span="24">
        <div v-highlight>
          <pre>
            <code class="html">{{useFaceCode}}</code>
          </pre>
        </div>
      </el-col>
    </el-row>


  </div>

</template>

<script>
import useFace from "@/views/V1.0.0/views/install/useFace";
export default {
  name: "index",
  data(){
    return{
      useFaceCode: useFace
    }
  }
}
</script>

<style scoped>

</style>
